<template>
  <v-card :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" md="8">
          <span
            v-text="`${$route.meta.title} ${loading ? '' : `(${data.length})`}`"
          />
        </v-col>
        <v-col
          v-if="
            (type === 1 && login.permissions.rhas.create) ||
            (type === 2 && login.permissions.programmeds.create) ||
            (type === 3 && login.permissions.openings.create)
          "
          cols="12"
          md="4"
          class="text-right"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="type == 1 && login.permissions.rhas.read && login.role_id < 3"
            tLabel="Layouts"
            tPosition="bottom"
            icon="mdi-file-search"
            color="info"
            size="x-small"
            :to="{
              name: `${page_route}.layouts`,
            }"
          />
          <BtnCircle
            v-if="type != 4 && login.permissions.rhas.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.agregar` : 'home',
            }"
          />
        </v-col>
        <v-col cols="12" v-if="new_rha_id && new_rha_id_alert">
          <v-alert v-model="new_rha_id_alert" type="info" dismissible dense>
            <div
              class="text-center"
              v-text="
                `El ingreso ${
                  base_folio + new_rha_id
                } fue registrado correctamente`
              "
            />
          </v-alert>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            label="Mostrar"
            v-model="filter"
            :items="filter_options"
            item-value="id"
            :item-text="(item) => item.value"
            dense
          />
        </v-col>
        <v-col cols="12" md="3" v-if="type != 4">
          <v-select
            label="Tipo"
            v-model="active"
            :items="active_options"
            item-value="id"
            :item-text="(item) => item.value"
            @change="getRHAs"
            dense
          />
        </v-col>
        <v-col v-if="login.role_id == 1 || login.role_id == 2" cols="12" md="3">
          <v-select
            label="Filtro"
            v-model="filter2"
            :items="filter2_opts"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            @change="getRHAs"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="!active">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'Filtros'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-spacer />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="ID/SM"
                    v-model="rha_search.enrollment"
                    type="text"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Nombre"
                    v-model="rha_search.name"
                    type="text"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Apellido paterno"
                    v-model="rha_search.first_surname"
                    type="text"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Apellido materno"
                    v-model="rha_search.second_surname"
                    type="text"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    label="Proveedor"
                    v-model="rha_search.provider_id"
                    :items="providers"
                    item-value="id"
                    :item-text="(item) => item.name"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    label="Estado"
                    v-model="rha_search.entity_id"
                    :items="entities"
                    item-value="id"
                    :item-text="(i) => i.entity"
                    :loading="entities_loading"
                    v-on:change="getMunicipalities()"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    label="Municipio"
                    v-model="rha_search.municipality_id"
                    :items="municipalities"
                    item-value="id"
                    :item-text="(i) => i.municipality"
                    :loading="municipalities_loading"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    label="Aseguradora"
                    v-model="rha_search.insurance_id"
                    :items="insurances"
                    item-value="id"
                    :item-text="(item) => item.name"
                    dense
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <DatePicker
                    label="Fecha de ingreso inicio"
                    :model.sync="rha_search.start_date"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <DatePicker
                    label="Fecha de ingreso final"
                    :model.sync="rha_search.end_date"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    label="AHORRO"
                    v-model="rha_search.save_money"
                    :items="save_money_opts"
                    item-value="id"
                    :item-text="(item) => item.name"
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn tile block color="info" @click="searchRhas">
                    <v-icon left v-text="'mdi-note-search'" />
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filterAmounts"
            :search="search"
            :loading="loading"
            :items-per-page="15"
            :item-class="rowBG"
          >
            <template v-slot:item.folio="{ item }">
              <div>
                <span class="font-weight-bold" v-text="item.folio" />
              </div>
            </template>

            <template v-slot:item.rha_opening_created_at="{ item }">
              <div>
                <span
                  :class="item.rha_opening ? '' : 'orange--text text--darken-3'"
                  v-text="
                    item.rha_opening_created_at
                      ? item.rha_opening_created_at
                      : 'PENDIENTE'
                  "
                />
              </div>
            </template>
            <template v-slot:item.delay_time="{ item }">
              <div>
                <span
                  :class="item.rha_opening ? '' : 'orange--text text--darken-3'"
                  v-text="item.delay_time"
                />
              </div>
            </template>
            <template v-slot:item.enrollment="{ item }">
              <div>
                <span
                  :class="
                    item.insured_id && !item.search_for_enrollment
                      ? 'pink--text'
                      : ''
                  "
                  v-text="item.enrollment"
                />
              </div>
            </template>

            <template v-slot:item.full_name="{ item }">
              <div>
                <span
                  :class="item.insured_id ? '' : 'orange--text text--darken-3'"
                  v-text="item.full_name"
                />
              </div>
            </template>

            <template v-slot:item.provider.with_module="{ item }">
              <v-icon
                v-if="item.provider && item.provider.with_module"
                color="success"
                small
              >
                mdi-crosshairs-gps
              </v-icon>
              <v-icon v-else small> mdi-crosshairs-off </v-icon>
            </template>

            <template v-slot:item.used_amount.used_amount="{ item }">
              <div>
                <span
                  v-text="
                    item.used_amount
                      ? numberFormat(item.used_amount.used_amount)
                      : ''
                  "
                />
              </div>
            </template>

            <template
              v-slot:item.authorized_amount.authorized_amount="{ item }"
            >
              <div v-if="item.authorized_amount">
                <span
                  v-if="item.authorized_amount.rha_amount_response_id === 1"
                  v-text="
                    numberFormat(item.authorized_amount.authorized_amount)
                  "
                />
                <span
                  v-else
                  v-text="item.authorized_amount.rha_amount_response"
                />
              </div>
            </template>

            <template v-slot:item.internment="{ item }">
              <v-icon :color="item.internment ? 'success' : 'warning'">
                mdi-{{ item.internment ? "check" : "alert" }}
              </v-icon>
            </template>

            <template v-slot:item.action="{ item, index }">
              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 || login.permissions.rha_amounts.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="
                      getColorBadge(
                        item.total_amounts,
                        item.total_status_amout_color
                      )
                    "
                    overlap
                    :content="`${item.total_amounts} | ${item.total_status_amout}`"
                    left
                  >
                    <v-btn
                      v-on="on"
                      fab
                      x-small
                      :color="
                        getColorBadge(
                          item.total_amounts,
                          item.total_status_amout_color
                        )
                      "
                      dark
                      :to="{
                        name: `${page_route}.amounts`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-currency-usd'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'Montos | Edos. cuenta'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 ||
                    (login.permissions.rha_catastrophics &&
                      login.permissions.rha_catastrophics.read))
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="getColorBadge(item.total_rha_catastrophics, 'teal')"
                    overlap
                    :content="item.total_rha_catastrophics"
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      fab
                      x-small
                      :color="getColorBadge(item.save_money, 'pink')"
                      dark
                      :to="{
                        name: `${page_route}.rha_catastrophics`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-notebook'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'Control de Costos'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 || login.permissions.rha_evolutions.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="getColorBadge(item.total_evolutions, 'purple')"
                    overlap
                    :content="item.total_evolutions"
                    style="padding-left: 5px"
                  >
                    <v-btn
                      v-on="on"
                      fab
                      x-small
                      :color="getColorBadge(item.total_evolutions, 'purple')"
                      dark
                      :to="{
                        name: `${page_route}.evolution`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-note-search'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'N. Evolución'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 || login.permissions.rha_surgicals.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="getColorBadge(item.total_surgicals, 'deep-purple')"
                    overlap
                    :content="item.total_surgicals"
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      fab
                      x-small
                      :color="
                        getColorBadge(item.total_surgicals, 'deep-purple')
                      "
                      dark
                      :to="{
                        name: `${page_route}.expedient`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-hospital-box'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'N.Quirúrgicas'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 ||
                    login.permissions.rha_administratives.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="getColorBadge(item.total_administratives, 'indigo')"
                    overlap
                    :content="item.total_administratives"
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      fab
                      x-small
                      :color="
                        getColorBadge(item.total_administratives, 'indigo')
                      "
                      dark
                      :to="{
                        name: `${page_route}.notes`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-note-multiple'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'N. Administrativas'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 ||
                    login.permissions.rha_visits_surveys.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="getColorBadge(item.total_visits_surveys, 'brown')"
                    overlap
                    :content="item.total_visits_surveys"
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      fab
                      x-small
                      :color="getColorBadge(item.total_surveys, 'orange')"
                      dark
                      :to="{
                        name: `${page_route}.visit_survey`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-clipboard-list'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'Visitas y Encuestas'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 || login.permissions.rha_demands.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="
                      getColorBadgeDemands(
                        item.demand_icon,
                        'orange',
                        item.demand_type_id
                      )
                    "
                    overlap
                    :content="item.demand_icon"
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      fab
                      x-small
                      :color="
                        !item.demand_responsed
                          ? getColorBadge(item.demand_icon, 'orange')
                          : 'grey'
                      "
                      dark
                      :to="{
                        name: `${page_route}.demands`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-text-box-check'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'Peticiones'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 || login.permissions.rha_openings.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="getColorBadge(item.total_openings, 'indigo')"
                    overlap
                    :content="item.total_openings"
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      fab
                      x-small
                      :color="getColorBadge(item.total_openings, 'indigo')"
                      dark
                      :to="{
                        name: `${page_route}.openings`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-file-delimited'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'Aperturas'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 || login.permissions.rha_loas.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="getColorBadge(item.total_loas, 'pink')"
                    overlap
                    :content="item.total_loas"
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      fab
                      x-small
                      :color="getColorBadge(item.total_loas, 'pink')"
                      dark
                      :to="{
                        name: `${page_route}.loas`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-file-compare'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'LOA'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type != 4 &&
                  (login.role_id === 1 || login.permissions.rha_letters.read)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="
                      item.color_letter
                        ? getColorBadgeLetters(
                            item.total_letters,
                            item.last_letter_review,
                            'brown'
                          )
                        : 'grey'
                    "
                    overlap
                    :content="
                      item.total_letters + ' | ' + item.last_letter_review
                    "
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-1"
                      v-on="on"
                      fab
                      x-small
                      :color="
                        item.color_letter
                          ? getColorBadge(item.total_letters, 'brown')
                          : 'grey'
                      "
                      dark
                      :to="{
                        name: `${page_route}.letters`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-file-certificate'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'Cartas | Revisión'" />
              </v-tooltip>

              <v-tooltip v-if="type != 4" top>
                <template v-slot:activator="{ on }">
                  <v-badge
                    bordered
                    :color="
                      getColorBadge(
                        item.contractor_detail.express_letter &&
                          item.rha_type_id != 2
                          ? 1
                          : 0,
                        'green'
                      )
                    "
                    overlap
                    :content="
                      item.contractor_detail.express_letter &&
                      item.rha_type_id != 2
                        ? 'E'
                        : ''
                    "
                    style="padding-left: 5px"
                  >
                    <v-btn
                      class="ml-5"
                      v-on="on"
                      fab
                      x-small
                      :color="getColorBadge(item.total_documents, 'cyan')"
                      dark
                      :to="{
                        name: `${page_route}.documents`,
                        params: { id: item.id },
                      }"
                    >
                      <v-icon v-text="'mdi-file-multiple'" />
                    </v-btn>
                  </v-badge>
                </template>
                <span v-text="'Documentos'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  type != 4 &&
                  (login.role_id === 1 || login.permissions.rha_evolutions.read)
                "
                top
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-2"
                    v-on="on"
                    fab
                    x-small
                    :color="
                      getColorBadge(
                        item.total_surgicals + item.total_evolutions,
                        'pink'
                      )
                    "
                    dark
                    @click="getResumeDoctors(item.id)"
                  >
                    <v-icon v-text="'mdi-stethoscope'" />
                  </v-btn>
                </template>
                <span v-text="'Resumen de médicos'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  type === 1 &&
                  (login.role_id === 1 ||
                    login.role_id === 2 ||
                    login.role_id === 11)
                "
                top
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-1"
                    v-on="on"
                    fab
                    x-small
                    color="success"
                    @click="
                      hospitalDischargedDlg(item.id, index, item.insurance_id)
                    "
                    :disabled="item.hospital_discharged ? true : false"
                  >
                    <v-icon> mdi-hospital-building </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Alta hospitalaria'" />
              </v-tooltip>

              <v-tooltip
                v-if="
                  type === 1 &&
                  (login.role_id === 1 || login.role_id === 11) &&
                  !item.discharge &&
                  (!item.insured_id ||
                    item.letter_is_express ||
                    (item.insured_id &&
                      (item.contractor_detail.dicharged_without_last_letter ||
                        item.total_letters == 0 ||
                        (item.total_letters > 0 && item.last_rha_letter_id))))
                "
                top
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-1"
                    v-on="on"
                    fab
                    x-small
                    color="deep-orange"
                    dark
                    @click="dischargeDialog(item.id, index)"
                  >
                    <v-icon> mdi-check </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Terminar'" />
              </v-tooltip>

              <v-tooltip v-if="type === 2 || type === 3" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-1"
                    v-on="on"
                    fab
                    x-small
                    :color="`${active ? 'red' : 'cyan'} darken-4`"
                    dark
                    @click="typeStatusSubmit(item.id)"
                  >
                    <v-icon v-text="`mdi-eye${active ? '-off' : ''}`" />
                  </v-btn>
                </template>
                <span
                  v-text="`${active ? 'Terminar' : 'Cambiar a pendiente'}`"
                />
              </v-tooltip>

              <v-tooltip v-if="type != 4" top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-1"
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    :to="{
                      name: page_route ? `${page_route}.detalle` : 'home',
                      params: { id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-file-eye'" />
                  </v-btn>
                </template>
                <span v-text="'Detalle'" />
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  type === 1 &&
                  item.rha_admission_type_id == 4 &&
                  (login.role_id === 1 ||
                    login.role_id === 2 ||
                    login.role_id === 11)
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-1"
                    v-on="on"
                    fab
                    dark
                    x-small
                    color="brown darken-4"
                    @click="reprogrammedDialog(item.id)"
                  >
                    <v-icon v-text="'mdi-calendar-refresh'" />
                  </v-btn>
                </template>
                <span v-text="'Reprogramar'" />
              </v-tooltip>

              <v-tooltip top v-if="type === 4">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="ml-1"
                    fab
                    x-small
                    color="warning"
                    dark
                    :to="{
                      name: 'facturacion.store',
                      params: { rha_id: item.id },
                    }"
                  >
                    <v-icon v-text="'mdi-file-send-outline'" />
                  </v-btn>
                </template>
                <span v-text="'Cargar archivos'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog
      v-model="dialog_resume_doctors"
      scrollable
      persistent
      max-width="1000px"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `${
                base_folio + rha_id
              } | Ingreso Hospitalario | Resumen de médicos`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="dialog_resume_doctors = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Médico</th>
                      <th class="text-left">Especialidad</th>
                      <th class="text-left">Tipo</th>
                      <th class="text-left">Visitas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in resume_doctors" :key="i">
                      <td v-text="parseInt(i) + 1" />
                      <td v-text="item.name" />
                      <td v-text="item.speciality" />
                      <td v-text="item.type_name" />
                      <td v-text="item.type_id > 3 ? 'N/A' : item.visits" />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="discharge_dialog"
      scrollable
      persistent
      max-width="1000px"
    >
      <v-card tile :loading="loading" :disabled="loading">
        <v-toolbar dark dense>
          <v-toolbar-title>
            {{ base_folio + rha_id }} | Ingreso Hospitalario | Terminar
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="dischargeDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form v-on:submit.prevent ref="discharge_form" lazy-validation>
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DATOS'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <DatePicker
                          label="Fecha de termino*"
                          :model.sync="discharge.date"
                          :rules="rules.required"
                          clean
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="Hora de termino*"
                          v-model="discharge.time"
                          :rules="rules.required"
                          type="time"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <BtnCircle
                  tLabel="Aceptar"
                  tPosition="top"
                  icon="mdi-check"
                  color="success"
                  size="x-small"
                  :click="dischargeSubmit"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="hospital_discharged_dlg"
      scrollable
      persistent
      max-width="1000px"
    >
      <v-card tile :loading="loading" :disabled="loading">
        <v-toolbar dark dense>
          <v-toolbar-title>
            {{ base_folio + rha_id }} | Ingreso Hospitalario | Alta hospitalaria
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="hospitalDischargedDlgClose">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form
            v-on:submit.prevent
            ref="hospital_discharged_form"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'DATOS'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <DatePicker
                          label="Fecha de alta*"
                          :model.sync="hospital_discharged.date"
                          :rules="rules.required"
                          clean
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="Hora de alta*"
                          v-model="hospital_discharged.time"
                          :rules="rules.required"
                          type="time"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <BtnCircle
                  tLabel="Aceptar"
                  tPosition="top"
                  icon="mdi-check"
                  color="success"
                  size="x-small"
                  :click="hospitalDischargedSubmit"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="reprogrammed_dialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card tile :loading="loading" :disabled="loading">
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="`${base_folio + rha_id} | Ingreso Hospitalario`"
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="reprogrammedDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form v-on:submit.prevent ref="reprogrammed_form" lazy-validation>
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'REPROGRAMAR'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <v-select
                          label="Acción"
                          v-model="reprogrammed.action"
                          :items="reprogrammed_actions"
                          item-value="id"
                          :item-text="(i) => i.value"
                        />
                      </v-col>
                      <v-col cols="12" v-if="reprogrammed.action">
                        <DatePicker
                          label="Fecha*"
                          :model.sync="reprogrammed.admission_date"
                          :rules="rules.required"
                          clean
                          enableAfterDate
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <BtnCircle
                  tLabel="Aceptar"
                  tPosition="top"
                  icon="mdi-check"
                  color="success"
                  size="x-small"
                  :click="reprogrammedSubmit"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="express_letter_dialog"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="`${base_folio + new_rha_id} | Carta Exprés`"
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="express_letter_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      La carta exprés ha sido generada
                      <br />
                      <small>
                        En caso de consultarla posteriormente ir a el área de
                        cartas del ingreso
                      </small>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        block
                        color="success"
                        small
                        :href="url_pdf + '/carta/' + express_letter + '/' + 1"
                        target="_blank"
                      >
                        Ver documento
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  URL_PDF,
  URL_DOCUMENTS,
  rules,
  dateTimeNow,
} from "../../control";
import { index } from "../../requests/pageRequest";
import { catalog } from "../../requests/catalogRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      filter: 0,
      page_route: null,
      login: this.$store.getters.getLogin,
      loading: true,
      data: [],
      search: "",
      headers: [
        { text: "Folio", filterable: true, value: "folio", width: "50" },
        {
          text: "F. registro",
          filterable: true,
          value: "created_at",
          width: "105px",
        },
        {
          text: "F. ingreso",
          filterable: true,
          value: "admission_date",
          width: "105px",
        },
        {
          text: "F. apertura",
          filterable: true,
          value: "rha_opening_created_at",
          width: "105px",
        },
        {
          text: "Tiempo",
          filterable: true,
          value: "delay_time",
          width: "60px",
        },
        { text: "Aseguradora", filterable: true, value: "insurance.name" },
        { text: "Episodio", filterable: true, value: "episode" },
        { text: "ID SM / Póliza", filterable: true, value: "enrollment" },
        { text: "Asegurado", filterable: true, value: "full_name" },
        { text: "Oficina", filterable: true, value: "office_address" },
        { text: "Contratante", filterable: true, value: "contractor" },
        { text: "Hospital", filterable: true, value: "trade_name" },
        { text: "Módulo SM", filterable: true, value: "provider.with_module" },
        {
          text: "Alta hospitalaria",
          filterable: true,
          value: "hospital_discharged",
        },
        {
          text: "F. M. Gastado",
          filterable: true,
          value: "used_amount.created_date",
          width: "105px",
        },
        {
          text: "M. Gastado",
          filterable: true,
          value: "used_amount.used_amount",
        },
        {
          text: "M. Autorizado",
          filterable: true,
          value: "authorized_amount.authorized_amount",
        },
        {
          text: "ICD",
          filterable: true,
          value: "icd",
        },
        {
          text: "T.ingreso",
          filterable: true,
          value: "rha_description.name",
        },
        {
          text: "T. internamiento",
          filterable: true,
          value: "internment",
        },
        {
          text: "Acción",
          value: "action",
          sortable: false,
          width: "0px",
        },
      ],
      filter_options: [
        { id: 2, value: "RECHAZADO" },
        { id: 3, value: "INFORMACIÓN ADICIONAL" },
        { id: 0, value: "TODOS" },
      ],
      active_options: [
        { id: 1, value: "PENDIENTES" },
        { id: 0, value: "TERMINADAS" },
      ],
      rules: rules(),
      dialog_resume_doctors: false,
      resume_doctors: {},
      discharge_dialog: false,
      discharge: {},
      hospital_discharged_dlg: false,
      hospital_discharged: {},
      reprogrammed_dialog: false,
      reprogrammed: {},
      reprogrammed_actions: [
        { id: 2, value: "INGRESAR A HOSPITALIZADOS" },
        { id: 1, value: "REPROGRAMAR" },
        { id: 0, value: "CANCELAR" },
      ],
      rha_id: 0,
      active: 1,
      type: null,
      new_rha_id: null,
      express_letter: null,
      express_letter_dialog: false,
      url_pdf: URL_PDF,
      base_folio: 0,
      new_rha_id_alert: false,
      providers: [],
      insurances: [],
      rha_search: {
        provider_id: "",
        insurance_id: "",
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        start_date: "",
        end_date: "",
        entity_id: null,
        municipality_id: null,
        save_money: 0,
      },
      entities: [],
      entities_loading: false,
      municipalities: [],
      municipalities_loading: false,
      filter2: 2,
      filter2_opts: [
        {
          id: 0,
          name: "SIN MÓDULO SM",
        },
        {
          id: 1,
          name: "CON MÓDULO SM",
        },
        {
          id: 2,
          name: "TODOS",
        },
      ],
      save_money_opts: [
        {
          id: 0,
          name: "TODOS",
        },
        {
          id: 1,
          name: "SOLO CON AHORRO",
        },
      ],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleTableColumns() {
      if (this.type == 1 || this.type == 2) {
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "rha_opening_created_at"),
          1
        );
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "delay_time"),
          1
        );
      }
      if (this.type == 2 || this.type == 3) {
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "used_amount.used_amount"),
          1
        );
      }
      if (this.type == 3) {
        this.headers.splice(
          this.headers.findIndex(
            (v) => v.value === "authorized_amount.authorized_amount"
          ),
          1
        );
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "used_amount.created_date"),
          1
        );
      }
      if (
        this.login.role_id != 1 &&
        this.login.role_id != 2 &&
        this.login.role_id != 11
      ) {
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "contractor"),
          1
        );
        this.headers.splice(
          this.headers.findIndex((v) => v.value === "icd"),
          1
        );
      }
    },
    widthActions() {
      const x = 63;
      let w = 0;

      if (this.type != 4) {
        w = x * 2;
      } else {
        w = x;
      }

      if (this.type != 4) {
        w = this.login.permissions.rha_amounts.read ? (w += x) : w;
        w = this.login.permissions.rha_evolutions.read ? (w += x) : w;
        w = this.login.permissions.rha_evolutions.read ? (w += x) : w; // Para ver resumen de medicos
        w = this.login.permissions.rha_surgicals.read ? (w += x) : w;
        w = this.login.permissions.rha_administratives.read ? (w += x) : w;
        w = this.login.permissions.rha_visits_surveys.read ? (w += x) : w;
        w = this.login.permissions.rha_demands.read ? (w += x) : w;
        w = this.login.permissions.rha_letters.read ? (w += x) : w;
        w = this.login.permissions.rha_openings.read ? (w += x) : w;
      }
      const i = this.headers.findIndex((v) => v.value === "action");
      this.headers[i].width = `${w}px`;
    },
    getRHAs() {
      if (this.active) {
        this.rha_search.start_date = "";
        this.rha_search.end_date = "";
        this.searchRhas();
      } else {
        this.rha_search.start_date = dateTimeNow().substring(0, 10);
        this.rha_search.end_date = dateTimeNow().substring(0, 10);
        this.data = [];
      }
    },
    searchRhas() {
      this.loading = true;
      index("rha", this.login.token, [
        { name: "user_id", value: this.login.id },
        { name: "type", value: this.type },
        { name: "active", value: this.active },
        { name: "provider_id", value: this.rha_search.provider_id },
        { name: "insurance_id", value: this.rha_search.insurance_id },
        { name: "enrollment", value: this.rha_search.enrollment },
        { name: "name", value: this.rha_search.name },
        { name: "first_surname", value: this.rha_search.first_surname },
        { name: "second_surname", value: this.rha_search.second_surname },
        { name: "start_date", value: this.rha_search.start_date },
        { name: "end_date", value: this.rha_search.end_date },
        {
          name: "municipality_id",
          value: this.rha_search.municipality_id
            ? this.rha_search.municipality_id
            : "",
        },
        {
          name: "entity_id",
          value: this.rha_search.entity_id ? this.rha_search.entity_id : "",
        },
        { name: "save_money", value: this.rha_search.save_money },
        { name: "filter2", value: this.filter2 },
      ]).then((response) => {
        //console.log(response);
        this.data = response.data;
        this.loading = false;
      });
    },
    getColorBadge(counter, color) {
      if (counter !== 0) {
        return color;
      }
      return "grey";
    },
    getColorBadgeVisitsSurveys(visits, surveys) {
      if (surveys === 0) {
        return "orange";
      } else {
        if (visits !== 0) {
          return "brown";
        }
      }
      return "grey";
    },
    getColorBadgeLetters(letters, letter_review, color) {
      if (letters === 0) {
        return "grey";
      } else if (letter_review !== "T") {
        return "orange";
      }
      return color;
    },
    getColorBadgeDemands(counter, color, type) {
      if (counter != 0) {
        if (type == 4) {
          return "red";
        }
        return color;
      }
      return "grey";
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getResumeDoctors(rha_id) {
      this.rha_id = rha_id;

      index("rhas/doctors/" + rha_id, this.login.token, []).then((response) => {
        this.resume_doctors = response.data;
        this.dialog_resume_doctors = true;
      });
    },
    dischargeDefaultData() {
      const dateTime = dateTimeNow();

      this.discharge = {
        id: null,
        date: dateTime.substring(0, 10),
        time: dateTime.substring(11, 16),
        index: null,
      };
    },
    dischargeDialog(id, index) {
      this.rha_id = id;
      this.dischargeDefaultData();
      this.discharge.id = id;
      this.discharge.index = index;
      this.discharge_dialog = true;
    },
    dischargeDialogClose() {
      this.discharge_dialog = false;
      this.dischargeDefaultData();
      this.$refs.discharge_form.resetValidation();
    },
    dischargeSubmit() {
      if (this.$refs.discharge_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma el alta del ingreso?"))
          .then((response) => {
            if (response.isConfirmed) {
              this.dischargeHandle(this.discharge);
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    dischargeHandle(discharge) {
      this.loading = true;

      Axios.post(
        URL_API + "/rhas/discharge",
        discharge,
        headersToken(this.login.token)
      ).then((response) => {
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          this.data[discharge.index].discharge = response.data.discharge;
          this.dischargeDialogClose();
          this.getRHAs();
        } else {
          console.log(response.data.message);
        }

        this.loading = false;
      });
    },
    hospitalDischargedDft() {
      const dateTime = dateTimeNow();

      this.hospital_discharged = {
        id: null,
        date: dateTime.substring(0, 10),
        time: dateTime.substring(11, 16),
        index: null,
        insurance_id: null,
      };
    },
    hospitalDischargedDlg(id, index, insurance_id) {
      this.rha_id = id;
      this.hospitalDischargedDft();
      this.hospital_discharged.id = id;
      this.hospital_discharged.index = index;
      this.hospital_discharged.insurance_id = insurance_id;
      this.hospital_discharged_dlg = true;
    },
    hospitalDischargedDlgClose() {
      this.hospital_discharged_dlg = false;
      this.hospitalDischargedDft();
      this.$refs.hospital_discharged_form.resetValidation();
    },
    hospitalDischargedSubmit() {
      if (this.$refs.hospital_discharged_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma el alta del ingreso?"))
          .then((res) => {
            if (res.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/rhas/hospital_discharged",
                this.hospital_discharged,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.data[
                    this.hospital_discharged.index
                  ].hospital_discharged = res.data.hospital_discharged;

                  if (this.hospital_discharged.insurance_id != 2) {
                    this.dischargeHandle({
                      id: this.hospital_discharged.id,
                      date: this.hospital_discharged.date,
                      time: this.hospital_discharged.time,
                      index: this.hospital_discharged.index,
                    });
                  }

                  this.hospitalDischargedDlgClose();
                } else {
                  console.log(res.data.message);
                }

                this.loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    typeStatusSubmit(id) {
      this.$swal
        .fire(msgConfirm(`¿Confirma el cambio de estado de este registro?`))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            Axios.post(
              URL_API + "/rhas/type/status",
              { id: id, rha_type_id: this.type },
              headersToken(this.login.token)
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.data.success ? "success" : "error",
                  response.data.message
                )
              );

              if (response.data.success) {
                this.getRHAs();
              } else {
                console.log(response.data.message);
              }
            });
          }
        });
    },
    reprogrammedDefaultData() {
      this.reprogrammed = {
        id: this.rha_id,
        admission_date: "",
        updated_by_id: this.login.id,
        action: 2,
      };
    },
    reprogrammedDialog(id) {
      this.rha_id = id;
      this.reprogrammedDefaultData();
      this.reprogrammed_dialog = true;
    },
    reprogrammedDialogClose() {
      this.reprogrammed_dialog = false;
      this.reprogrammedDefaultData();
      this.$refs.reprogrammed_form.resetValidation();
    },
    reprogrammedSubmit() {
      if (this.$refs.reprogrammed_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma acción para el ingreso?"))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              Axios.post(
                URL_API + "/rhas/reprogrammed",
                this.reprogrammed,
                headersToken(this.login.token)
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );
                if (response.data.success) {
                  this.reprogrammedDialogClose();
                  this.getRHAs();
                } else {
                  console.log(response.data.message);
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rowBG: function (item) {
      let bg = "";

      if (this.type != 4) {
        if (
          (this.type === 1 || this.type === 3) &&
          item.rha_admission_type_id == 4
        ) {
          bg = "brown lighten-4";
        }
      } else {
        if (item.discharge != null) {
          bg = "green lighten-4";
        }
      }

      if (item.petition) {
        bg = "cyan lighten-4";
      }

      if (item.demand_type_id == 4) {
        bg = "yellow lighten-4";
      }

      return bg;
    },
    getMunicipalities() {
      this.municipalities_loading = true;

      index(
        "municipalities/" + this.rha_search.entity_id,
        this.login.token,
        []
      ).then((res) => {
        this.municipalities = res.data;

        this.municipalities.push({
          id: null,
          municipality: "TODOS",
        });

        this.rha_search.municipality_id = null;
        this.municipalities_loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;

    switch (this.$route.name) {
      case "admission_request":
        this.type = 1;
        break;
      case "programaciones":
        this.type = 2;
        break;
      case "aperturas":
        this.type = 3;
        break;
      case "facturacion":
        this.type = 4;
        break;
    }

    index("rha/getbasefolio", this.login.token, []).then((response) => {
      this.base_folio = response.data;
    });

    if (this.login.role_id == 1) {
      index("providers/type/4", this.login.token, []).then((response) => {
        this.providers = response.data;
      });

      index("insurances", this.login.token, []).then((response) => {
        this.insurances = response.data;
      });
    } else {
      index("user/" + this.login.id + "/providers", this.login.token, []).then(
        (response) => {
          this.providers = response.data;

          if (this.providers.length == 1) {
            this.rha_search.provider_id = this.providers[0].id;
          }
        }
      );

      index("user/" + this.login.id + "/insurances", this.login.token, []).then(
        (response) => {
          this.insurances = response.data;

          if (this.insurances.length == 1) {
            this.rha_search.insurance_id = this.insurances[0].id;
          }
        }
      );
    }

    catalog("entities", this.login.token).then((res) => {
      this.entities = res;

      this.entities.push({
        id: null,
        entity: "TODOS",
      });

      this.municipalities.push({
        id: null,
        municipality: "TODOS",
      });

      this.rha_search.entitiy_id = null;
      this.entities_loading = false;
    });

    this.handleTableColumns();
    this.widthActions();
    this.getRHAs();

    if (this.$route.params.new_rha_id) {
      this.new_rha_id = this.$route.params.new_rha_id;
      this.new_rha_id_alert = true;
    }

    this.express_letter = this.$route.params.express_letter
      ? parseInt(this.$route.params.express_letter)
      : null;

    if (this.new_rha_id && this.express_letter != 0 && this.type != 2) {
      this.express_letter_dialog = true;
    }
  },
  computed: {
    filterAmounts() {
      if (this.filter === 0) return this.data;
      return this.data.filter((item_list) =>
        item_list.authorized_amount
          ? item_list.authorized_amount.rha_amount_response_id === this.filter
          : null
      );
    },
  },
};
</script>